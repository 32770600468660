.box {
  min-width: 200px;
  height: 170px;
  margin: 10px;
  background: white;
  border-radius: 8px;
  display: flex; /* Enable flexbox */
  flex-direction: column; /* Stack children vertically */
}

.scanBox {
  width: 208px;
  height: 170px;
  margin: 10px;
  background: white;
  border-radius: 8px;
  /* border: 1px solid grey; */
  display: flex; /* Enable flexbox */
  flex-direction: column; /* Stack children vertically */
}

.scanBox div {
  display: flex;
  align-items: center;
  height: 100px;
  padding-left: 8px;
}

.top-head {
  font-size: 17px; /* Larger font size for heading */
  font-weight: 600;
  padding-top: 8px;
  text-align: left;
  color: #838383;
  padding-left: 10px;
}

.box div {
  display: flex; /* Enable flexbox for counter */
  align-items: center; /* Center content vertically */
  height: 100px;
}

.cont-text {
  font-size: 25px;
  font-weight: 600;
  padding-left: 13px;
}
.red {
  color: red;
}
.div {
  width: 96%; /* Adjust width as needed */
  height: 200px; /* Adjust height as needed */
  margin: 10px; /* Spacing between boxes */
  font-weight: bold;
  background: white; /* Text color */
  border-radius: 8px; /* Rounded corners */
  /* border: 1px solid grey; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.my {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.my p {
  margin-bottom: 0;
}

.heading {
  color: #838383;
  padding-left: 12px;
  font-size: 20px;
  font-weight: 700;
}
.my-pharma-container {
  display: flex;
  padding: 30px !important;
  width: 100%;
}

.my-pharma-box {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.container {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
  width: 100%;
}

.a {
  display: grid; /* Use grid layout */
  grid-template-columns: 1fr 1fr; /* Two equal columns */
  grid-template-rows: auto auto; /* Two rows */
  width: 100%; /* Adjust as needed */
  height: 200px; /* Adjust as needed */
  background: white; /* Text color */
  border-radius: 8px; /* Rounded corners */
  /* border: 1px solid grey; */
  margin-top: 10px;
  padding: 20px;
  padding-left: 50px;
}

.top-left {
  grid-column: 1; /* First column */
  grid-row: 1; /* First row */
}

.top-right {
  grid-column: 2; /* Second column */
  grid-row: 1; /* First row */
}

.bottom-left {
  grid-column: 1; /* First column */
  grid-row: 2; /* Second row */
}

.bottom-right {
  grid-column: 2; /* Second column */
  grid-row: 2; /* Second row */
}

.head {
  margin-bottom: 0px;
  font-weight: bold;
  font-size: 17px; /* Larger font size for heading */
  font-weight: 600;
  padding-top: 8px;
  text-align: left;
  color: #838383;
}

.count {
  margin-bottom: 0px;
  font-size: 26px; /* Larger font size for heading */
  font-weight: 600;
}
