@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');

/* font-family: 'Montserrat', sans-serif; */
/* font-family: 'Roboto', sans-serif; */
:root {
  --color-logo: #000fd0;
}
.sortable th {
  position: relative;
  float: left;
}
.sortable th:before {
  content: '';
  position: absolute;
  right: -26px;
  bottom: 0;
  background-image: url(../public/media/ePulse/icons/sort_default.svg);
  /* float: right; */
  width: 20px;
  height: 20px;
  background-size: 20px;
  opacity: 0.15;
}
.sortable.asc th:before {
  background-image: url(../public/media/ePulse/icons/sort_asc.svg);
  opacity: 0.5;
}
.sortable.desc th:before {
  background-image: url(../public/media/ePulse/icons/sort_desc.svg);
  opacity: 0.5;
}

/* CSS FOR APP START */
.wrapper {
  font-family: 'Montserrat', sans-serif;
}
.wrapper h2.page-title {
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
  font-weight: 700;
}

.header {
  z-index: 95 !important;
}
.sidebar {
  height: 100%;
  width: 88px;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #111;
  overflow-x: hidden;
  white-space: nowrap;
  transition: all 0.5s !important;
}
.sidebar.expanded {
  width: 220px;
}
.sidebar + .wrapper {
  transition: all 0.5s !important;
}
.sidebar.expanded + .wrapper {
  padding-left: 220px;
}
.sidebar + .wrapper .header {
  transition: all 0.5s !important;
}
.sidebar.expanded + .wrapper .header {
  left: 220px;
}
.toggle-icon {
  cursor: pointer;
  width: 24px;
  height: 24px;
  position: absolute;
  right: -12px;
  top: 61px;
  transition: all 0.5s !important;
}
.sidebar.expanded .toggle-icon {
  transform: rotate(180deg);
}
.sidebar.expanded .menu-item {
  font-size: 14px;
}
.asaside-nav .menu-item:hover .menu-link .menu-item,
.asaside-nav .menu-item.show .menu-link .menu-item {
  color: 3590A7;
}
.sidebar .menu-item .menu-link {
  font-size: 14px;
  justify-content: flex-start;
  /* max-width: 65px;  */
  font-family: 'Montserrat', sans-serif;
  color: #ffffff;
  text-decoration: none;
}
.sidebar .menu-item .menu-link .menu-icon {
  padding-left: 40px !important;
}
.sidebar .menu-item .menu-link .menu-text {
  margin-left: 10px;
  opacity: 0;
  transition: all 0.5s !important;
}
.sidebar.expanded .menu-item .menu-link .menu-text {
  opacity: 1;
}

#main {
  transition: margin-left 0.5s;
  padding: 16px;
  margin-left: 85px;
}

.aside-logo {
  position: relative;
}

.aside-logo:after {
  content: ' ';
  position: absolute;
  left: 25%;
  bottom: -3px;
  width: 50%;
  height: 1px;
  background-color: #fff;
  opacity: 0.3;
}
.asaside-nav .menu-link img {
  min-width: 24px;
}
.asaside-nav .menu-item:hover,
.asaside-nav .menu-item.show {
  background: #fbfcfd;
}
.asaside-nav .menu-item:hover .menu-link img,
.asaside-nav .menu-item.show .menu-link img {
  filter: invert(53%) sepia(10%) saturate(2771%) hue-rotate(145deg) brightness(90%) contrast(79%);
}

.asaside-nav .menu-sub-dropdown {
  border-radius: 0;
  box-shadow: none;
  padding: 0 !important;
}
.asaside-nav .menu-sub-dropdown .menu-item {
  background: #fbfcfd;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-bottom: 1px solid #eff3f5;
  padding: 0.5rem 0;
}
.menu-sub-dropdown .menu-item a span {
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-weight: normal;
}

.asaside-nav .menu-sub-dropdown .menu-item:hover,
.asaside-nav .menu-sub-dropdown .menu-item.show {
  background: #eff1f4;
  color: #0e0e0e;
}
.asaside-nav .menu-sub-dropdown .menu-item,
.asaside-nav .menu-sub-dropdown .menu-item a,
.asaside-nav .menu-sub-dropdown .menu-item a .menu-title {
  color: #0e0e0e !important;
}

.header-fixed .header {
  background: #fff;
  min-height: 75px;
}
.user-badge-block {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}
.user-badge-block .user-avatar {
  width: 32px;
  height: 32px;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 0.75rem;
}
.user-badge-block .user-avatar img {
  max-width: 100%;
  max-height: 100%;
}
.user-badge-block .user-name {
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  font-weight: 700;
  color: #1f1f1f;
  line-height: 1.2;
}
.user-badge-block .user-role {
  color: #cdcdcd;
  font-size: 10px;
  font-weight: 500;
}
.page-title h1 {
  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
  font-weight: bold;
  color: #4e4e55;
  margin-bottom: 0 !important;
}
.page-title .breadcrumb,
.page-title .breadcrumb .breadcrumb-item {
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  color: #838383;
  margin-top: 0 !important;
}
.page-title .breadcrumb .breadcrumb-item a {
  font-size: 14px;
  color: #838383;
  padding-right: 5px;
}
.page-header {
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 2rem;
}

.searchbox-wrapper {
  position: relative;
}
.searchbox-wrapper .serachbtn-wrapper {
  cursor: pointer;
  position: absolute;
  right: 1px;
  top: 1px;
  background: #fff;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  border-radius: 0 8px 8px 0;
  border-left: 1px solid #ddd;
}
.searchbox-wrapper .serachbtn-wrapper img {
  width: 20px;
}

.filter-row-notification {
  font-family: 'Montserrat', sans-serif;
  display: flex;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  align-items: center;
  justify-content: end;
}

.filter-row {
  font-family: 'Montserrat', sans-serif;
  display: flex;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  align-items: center;
}
.filter-row > * {
  margin: 0 8px;
}
.filter-row select {
  max-width: 170px;
  max-height: 38px;
  /* margin-right: 20px; */
  border-radius: 8px;
  border: 1px solid #ddd;
  background: #fff;
  padding: 0.65rem 1rem;
}
.filter-row input {
  max-width: 230px;
  max-height: 38px;
  padding: 0.65rem 1rem;
  /* margin-right: 20px; */
  border-radius: 8px;
  border: 1px solid #ddd;
  background: #fff;
}
.filter-row .filter-head {
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-weight: 600;
  white-space: nowrap;
}
.filter-row span.filter-action {
  color: #398aa6;
  font-size: 14px;
  font-weight: 600;
  text-decoration: underline;
  margin: 0 15px;
}
.filter-row button {
  margin-left: auto;
  height: 37px !important;
  font-weight: 600;
}

.list {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Adjust the number of columns as needed */
  grid-gap: 10px; /* Adjust the gap between items as needed */
}

.list-item {
  padding: 10px;
}

/* VERTICALTABS START */

.horizontal-tab-list {
  display: flex;
  flex-direction: row;
  /* border-bottom: 1px solid #ccc; */
  margin-bottom: 0.5rem;
}
.tab-list {
  display: flex;
  flex-direction: column;
}

.dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
}

/* VAERTICALTABEND */

.modalcontainer {
  position: relative;
}

.modalCalendar {
  position: absolute;
  top: 100%; /* position modal just below the div */
  right: 0;
  background-color: white;
  border: 1px solid black;
  padding: 10px;
  z-index: 1000;
}

.modalCalendarCustom {
  position: absolute;
  top: 10%; /* position modal just below the div */
  right: 5%;
  background-color: white;
  border: 1px solid black;
  padding: 10px;
  z-index: 1000;
}

.ePulse-roundedCorner {
  /* border-top-right-radius: 20px; */
  /* border-bottom-right-radius: 20px; */
  /* background-color: var(--color-logo); */
  /* background: linear-gradient(184.94deg, #29aaa9 -6.13%, #564ea2 68.99%); */
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background: linear-gradient(180deg, #29aaa9 0%, #564ea1 100%);
}

.ePulse-datePickerParent {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
}

.ePulse-datePicker {
  width: 80px;
  height: 30px;
  border: 0;
}

.ePulse-borderBottom-light {
  border-bottom-color: gray;
}

.ePulse-border-light-grey {
  border: 1px solid #979797;
}

.ePulse-border-light {
  border: 1px solid #c3c0c0;
}

.ePulse-border-top-light {
  border-top: 1px solid #c3c0c0;
}

.ePulse-border-bottom-light {
  border-bottom: 1px solid #c3c0c0;
}

.ePulse-border-primary {
  border: 1px solid var(--color-logo);
}

.ePulse-appColor-primary {
  /* background-color: var(--color-logo); */
  background: linear-gradient(269.89deg, #29aaa9 0.09%, #564ea2 99.92%);
}

.ePulse-Gradient-Color-button {
  background: linear-gradient(269.89deg, #29aaa9 0.09%, #564ea2 99.92%);
  border-radius: 20px;
}

.checkbutton {
  background-color: #eaeaea;
  /* color: white; */
}

.checkbutton:checked {
  background-color: #398aa6;
  border-color: #797979;
}

.ePulse-textColor-primary {
  /* color: linear-gradient(184.94deg, #29aaa9 -6.13%, #564ea2 68.99%) !important; */
  color: var(--color-logo) !important;
}

.ePulse-bg-light {
  background-color: #ece6f6 !important;
}

.ePulse-bg-muted {
  background-color: #eaeaea;
}

.ePulse-modal-97w {
  width: 97vw;
  max-width: 97vw;
}

.ePulse-modal-90w {
  width: 90vw;
  max-width: 90vw;
}
.ePulse-modal-70w {
  width: 70vw;
  max-width: 70vw;
}

.ePulse-modal-50w {
  width: 50vw;
  max-width: 50vw;
}

.ePulse-modal-30w {
  width: 30vw;
  max-width: 30vw;
}

.ePulse-BG-LightBlue {
  background-color: #e9efff;
  /* background-color: '#EFF1FE'; */
}

.ePulse-BG-Outlet {
  background-color: '#EFF1FE';
}

.ePulse-divider {
  /* border-bottom: 1px solid rgb(212 212 205 / 50%); */
  border-bottom: none;
}
.ePulse-pagination-button {
  /* background-color: #564ea1; */
  /* padding: 3pt; */
  /* align-items: center; */
  /* border-radius: 5pt; */
  /* border: 0; */
  border-radius: 8px;
  border: 1px solid #f1f1f1;
  background: #fff;
}
.ePulse-pagination-button img {
  margin: 5px 8px;
  width: 16px;
  height: 16px;
}

.ePulse-pagination-dropdown {
  height: 30px;
  /* border-radius: 5pt; */
  border-radius: 8px;
  border: 1px solid #ddd;
  background: #fff;
  padding: 0 10px;
}

.ePulse-button {
  height: 30px;
  color: #ffffff;
  background: linear-gradient(269.89deg, #29aaa9 0.09%, #564ea2 99.92%) !important;
  border: 0;
  padding-left: 12pt;
  padding-right: 12pt;
  border-radius: 10pt;
}
.display {
  display: flex;
  justify-content: center;
  margin-bottom: 12px;
  margin-top: 16px;
}
.ePulse-button2 {
  color: #ffffff;
  background: linear-gradient(269.89deg, #29aaa9 0.09%, #564ea2 99.92%) !important;
  border: 0;
  padding: 9pt;
  font-weight: 700;
  border-radius: 10pt;
  cursor: pointer;
}
.ePulse-button3 {
  background: #f0f0f0 !important;
  border: 0;
  padding: 9pt;
  font-weight: 700;
  border-radius: 10pt;
  cursor: pointer;
}
.ePulse-button-secondory {
  height: 30px;
  padding: 0 20px;
  border: 1px solid #c3c0c0;
  border-radius: 20px;
  color: #000000;
}

.ePulse-button-disabled {
  height: 30px;
  padding: 0 20px;
  border: 1px solid #c3c0c0;
  border-radius: 20px;
  color: #c3c0c0;
}

.custom-pagination .page-item.active .page-link {
  background: linear-gradient(269.89deg, #29aaa9 0.09%, #564ea2 99.92%) !important;
  /* background-color: var(--color-logo) !important;
  border-color: var(--color-logo) !important; */
}

.custom-pagination .page-link .page-item.active {
  color: #ffffff !important;
}

/* .my-custom-class td:nth-child(1) {
  width: '80%';
}

.my-custom-class td:nth-child(2) {
  width: 200px;
} */

/* CSS FOR APP END */

/* CSS FOR REACT-CALENDAR START */

.react-calendar {
  width: 440px;
  max-width: 100%;
  height: 320px;
  background: white;
  /* border: 1px solid #a0a096; */
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
  border-radius: 10px;
}
.react-calendar--doubleView {
  width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__navigation {
  display: flex;
  height: 24px; /* updated 44 to 24*/
  margin-bottom: 1em;
}
.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}
.react-calendar__navigation button:disabled {
  background-color: #f0f0f0;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
}
.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  font-weight: bold;
}
.react-calendar__month-view__days__day--weekend {
  color: #d10000;
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}
.react-calendar__tile {
  max-width: 100%;
  padding: 10px 6.6667px;
  background: none;
  text-align: center;
  line-height: 16px;
}
.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__tile--now {
  background: #ffff76;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #ffffa9;
}
.react-calendar__tile--hasActive {
  background: #76baff;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}
.react-calendar__tile--active {
  background: #006edc;
  color: white;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #1087ff;
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}

.component-card {
  display: flex;
  flex-wrap: wrap;
  margin: 1rem;
  height: 5rem;
  width: 17rem;
  background-color: #e6e6e6;
  align-items: center;
  justify-content: center;

  /* d-flex align-items-center justify-content-center col-sm-3 h-50px shadow-sm m-1 */
}
.component-card.active {
  display: flex;
  flex-wrap: wrap;
  margin: 1rem;
  height: 5rem;
  width: 17rem;
  background-color: #bcbcbc;
  align-items: center;
  justify-content: center;
  /* d-flex align-items-center justify-content-center col-sm-3 h-50px shadow-sm m-1 */
}

/* CSS FOR REACT-CALENDAR END */

/* Custom CSS by KW */

.login-section {
  font-family: 'Montserrat', sans-serif;
}

.login-section .form-group {
  position: relative;
  border: none;
  margin-bottom: 1.5rem;
  padding: 0;
}
.login-section .form-group .form-label {
  position: absolute;
  top: -10px;
  left: 10px;
  background: var(--white, #fff);
  padding: 0 5px;
}
.login-section .form-group .form-control {
  border-radius: 5px;
  border: 1px solid var(--light-grey, #d3d3d3);
  background: var(--white, #fff);
}
.login-section .form-error {
  color: #ff464b;
  font-size: 0.95em;
  font-weight: 500;
}
/* .login-section button {
  border-radius: 40px;
  background: linear-gradient(265deg, #29aaa9 0%, #564ea1 100%);
  color: var(--white, #fff) !important;
  max-width: 170px;
} */
.login-section button:hover {
  /* color: var(--white, #fff); */
  /* text-decoration: underline; */
}
.login-section .login-footer a {
  margin-right: 1rem;
  padding-right: 1rem;
  position: relative;
  /* border-right: 1px solid #eee; */
}
.login-section .login-footer a::after {
  content: ' ';
  position: absolute;
  right: 0;
  top: 4px;
  height: 12px;
  width: 1px;
  background-color: #ccc;
}
.login-section .login-footer a:last-child:after {
  display: none;
}
.login-banner {
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.login-banner h2 {
  color: #fff;
  font-size: 28px;
  font-weight: 700;
  letter-spacing: 3.08px;
  text-transform: uppercase;
}
.login-banner ul {
  list-style: none;
  display: flex;
  /* border-top: 1px solid #fff; */
  position: relative;
  margin-top: 10px;
}
.login-banner ul::before {
  content: ' ';
  position: absolute;
  left: 0;
  right: 0;
  top: -10px;
  height: 1px;
  width: 100%;
  background-color: #fff;
  opacity: 0.8;
}
.login-banner ul li {
  margin-right: 1rem;
  padding-right: 1rem;
  position: relative;
  /* border-right: 1px solid #eee; */
}
.login-banner ul li::after {
  content: ' ';
  position: absolute;
  right: 0;
  top: 4px;
  height: 12px;
  width: 1px;
  background-color: #fff;
}
.login-banner ul li:last-child:after {
  display: none;
}
@media all and (max-width: 778px) {
  .login-banner {
    display: none;
  }
  .login-section,
  .login-section > div {
    width: 100%;
  }
}

.login-back-box {
  position: absolute;
  top: 20px;
  right: 30px;
}
.login-back-link {
  text-decoration: underline;
  font-size: medium;
  cursor: pointer;
}

/* List page CSS updates */
.admin-table thead {
  background-color: #eeeeee;
}
.admin-table thead th {
  font-family: 'Montserrat', sans-serif;
  font-size: 13px;
  font-weight: 600;
  text-align: left;
  color: #4e4e55 !important;
}
.admin-table thead th .unmatched {
  /* font-family: 'Montserrat', sans-serif; */
  font-size: 11px;
  font-weight: 0;
  color: #4e4e55 !important;
}
.admin-table thead tr > th {
  text-align: left;
  min-width: 100px;
}
.admin-table tbody tr {
  border-bottom: 1px solid #eeeeee;
}
.admin-table tbody td {
  text-align: left;
}
.admin-table tbody td span {
  font-family: 'Roboto', sans-serif;
  font-size: 13px !important;
  color: #6c6c6d !important;
  font-weight: 400 !important;
}
.admin-table tbody td span svg {
  width: 18px !important;
  height: 18px !important;
}
.admin-table .pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.admin-table .pagination .page-show span {
  font-family: 'Montserrat', sans-serif;
  font-size: 13px;
  font-weight: 500;
  margin-right: 5px;
}
.admin-table .pagination .page-num {
  display: flex;
  align-items: center;
}

/* Dashboard CSS */
.dashboard-landing .dashboard-counters {
  margin-bottom: 15px;
}
.dashboard-landing .dashboard-counters .count-block {
  background-color: #fff;
  box-shadow: 0px 2px 12px rgba(37, 51, 66, 0.15);
  padding: 5px 15px;
  border-radius: 8px;
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: 'Montserrat', sans-serif;
}
.dashboard-landing .dashboard-counters .count-block .count-block-content {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  /* line-height: 3; */
}
.dashboard-landing .dashboard-counters .count-block .count-block-content .content-name {
  color: #4e4e55;
  font-size: 16px;
  font-weight: normal;
  margin: 0.25rem 0;
  line-height: 1.2;
}
.dashboard-landing .dashboard-counters .count-block .count-block-content .content-count {
  color: #000000;
  font-size: 24px;
  font-weight: 600;
  margin: 0.25rem 0;
}
.dashboard-landing .dashboard-counters .count-block:last-child {
  margin-right: 0;
}
.dashboard-landing .dashboard-block {
  background-color: #fff;
  box-shadow: 0px 2px 12px rgba(37, 51, 66, 0.15);
  /* padding: 15px; */
  border-radius: 8px;
  margin-bottom: 1.5rem;
}
.dashboard-landing .dashboard-block .dashboard-block-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 10px 15px;
  border-bottom: 1px solid #ecf0f2;
}
.dashboard-landing .dashboard-block .dashboard-block-header .header-left {
  display: flex;
  align-items: center;
}
.dashboard-landing .dashboard-block .dashboard-block-header .header-left img {
  max-width: 32px;
}
.dashboard-landing .dashboard-block .dashboard-block-header .block-title {
  font-size: 18px;
  font-weight: 600;
  margin-left: 1rem;
}
.dashboard-landing .dashboard-block .dashboard-block-header .link-view-all {
  font-size: 12px;
  color: #398aa6;
  font-weight: 500;
  margin-left: 1rem;
}
.dashboard-landing .dashboard-block .dashboard-block-header .header-right {
  border-radius: 23px;
  border: 1px solid #398aa6;
  padding: 0.25rem 0.5rem;
}
.dashboard-landing .dashboard-block .dashboard-block-header .header-right select {
  border: none !important;
  outline: none !important;
}
.dashboard-landing .dashboard-block .dashboard-block-body {
  margin: 5px 10px;
  padding: 5px;
  /* overflow: hidden auto; */
}
.dashboard-landing .dashboard-block.dashboard-block-activities .dashboard-block-header,
.dashboard-landing .dashboard-block.dashboard-block-requests .dashboard-block-header {
  justify-content: flex-start;
}
.dashboard-landing .dashboard-block.dashboard-block-activities .dashboard-block-header .block-title,
.dashboard-landing .dashboard-block.dashboard-block-requests .dashboard-block-header .block-title {
  font-size: 14px;
}
.dashboard-landing .dashboard-block.dashboard-block-activities .dashboard-block-body,
.dashboard-landing .dashboard-block.dashboard-block-requests .dashboard-block-body {
  padding: 5px 0;
}
.dashboard-landing .dashboard-block.dashboard-block-activities .dashboard-block-body > div,
.dashboard-landing .dashboard-block.dashboard-block-requests .dashboard-block-body > div {
  padding: 5px;
}
.dashboard-landing .dashboard-block .dashboard-info-row {
  border-bottom: 1px solid #ecf0f2;
  margin: 0.5rem 0;
  padding-bottom: 1rem;
}
.dashboard-landing .dashboard-block .dashboard-info-row > div {
  display: flex;
  align-items: flex-start;
}
.dashboard-landing .dashboard-block .dashboard-info-row > div img {
  width: 24px;
  height: 24px;
  filter: invert(51%) sepia(11%) saturate(2329%) hue-rotate(149deg) brightness(92%) contrast(81%);
}
.dashboard-landing .dashboard-block .dashboard-info-row .info-title {
  color: #343a40;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.3;
  /* margin-left: 0.5rem; */
}
.dashboard-landing .dashboard-block .dashboard-info-row .info-date {
  color: #9babb8;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.3;
  /* margin-left: 0.5rem; */
}
.dashboard-landing .dashboard-block .dashboard-info-row .info-time {
  color: #adb5bd;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.2;
  margin-top: 0.5rem;
}
.dashboard-landing .dashboard-block .dashboard-info-row.request-row {
  cursor: pointer;
}
.dashboard-landing .dashboard-block .dashboard-info-row.request-row .info-title {
  margin-left: 0;
  color: #398aa6;
  font-weight: 600;
}
.dashboard-landing .dashboard-block .dashboard-info-row.request-row .view-icon {
  filter: invert(51%) sepia(11%) saturate(2329%) hue-rotate(149deg) brightness(92%) contrast(81%);
  cursor: pointer;
}
.dashboard-table-row {
  display: flex;
  padding: 0.5rem;
  border-bottom: 1px solid #ecf0f2;
}
.dashboard-table-row > div {
  font-size: 0.8em;
  line-height: 1.2;
}
.dashboard-table-row .dashboard-table-icon {
  height: 36px;
  width: 36px;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 1rem;
}
.dashboard-table-row .col-label {
  font-size: 12px;
}
.dashboard-table-row .col-value {
  font-size: 14px;
  /* font-weight: 600; */
}

.dashboard-table-row-header {
  background-color: #eeeeee;
  margin: 5px 10px;
  padding: 7px;
}
.wrapper hr {
  border-top: 1px solid #ecf0f2;
  opacity: 0.9;
}
.wrapper .vertical-tabs {
  background: #fff;
  display: flex;
  flex-direction: row;
}
.wrapper .vertical-tabs .tab-list {
  display: flex;
  flex-direction: column;
  margin: 1rem 0.5rem;
  padding: 0 0.5rem;
}
.wrapper .vertical-tabs .tab-content {
  padding: 1.5rem 1rem;
  flex: 1;
}

.ePulse-modal-content .wrapper {
  padding: 0;
}

.wrapper .vertical-tabs {
  background: #fff;
  display: flex;
  flex-direction: row;
}
.wrapper .vertical-tabs .tab-content {
  padding: 1.5rem 1rem;
  flex: 1;
}

.wrapper .vertical-tabs .tab-content {
  padding: 1.5rem 1rem;
  flex: 1;
}

.wrapper .vertical-tabs .horizontal-tab-list .tab {
  /* margin: 0.5rem; */
}

.wrapper .vertical-tabs .tab-head {
  padding: 10px;
  background-color: #bdbdbd;
  color: #ffffff;
  font-size: 1.3rem;
  font-weight: 600 !important;
  border: none;
  cursor: pointer;
  margin: 1rem;
  border-radius: 0;
  font-weight: 500;
  background-image: url(../public/media/ePulse/icons/chevron_down.svg);
  background-position: right 10px top 6px;
  background-repeat: no-repeat;
  background-size: 30px;
  display: none;
}
.wrapper .vertical-tabs .tab {
  padding: 10px;
  background-color: #f1f1f1;
  border: none;
  cursor: pointer;
  margin: 0.5rem 0;
  border-radius: 0;
  font-weight: 500;
}
.wrapper .vertical-tabs .horizontal-tab-list .tab {
  /* margin: 0.5rem; */
}
.wrapper .vertical-tabs .tab.active {
  background-color: #bdbdbd;
  color: #ffffff;
}

.wrapper .form-wrapper input,
.wrapper .form-wrapper select,
.wrapper .form-wrapper textarea {
  background-color: rgb(239 241 249 / 60%);
  border: none;
}
.wrapper .form-wrapper input:enabled:hover {
  border: 0.5px solid #bdbdbd !important;
}

.wrapper .form-wrapper .form-block {
  margin-bottom: 1rem;
  padding: 0 1rem;
}
.wrapper .form-wrapper .form-label {
  font-weight: 500;
  margin-bottom: 0.25rem;
}
.ePulse-modal {
  font-family: 'Montserrat', sans-serif;
}
.ePulse-modal .modal-header {
  border-bottom: 1px solid rgb(0 0 0 / 5%);
  font-size: 18px;
  font-weight: 500;
}
.ePulse-modal .modal-header button {
  background-color: #bab9b9;
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(241deg) brightness(103%) contrast(103%);
  border-radius: 50%;
  width: 0.3rem;
  height: 0.3rem;
}
.ePulse-modal .ePulse-modal-content {
  padding: 1rem;
}
.ePulse-modal .ePulse-modal-content .form-block {
  display: flex;
  flex-direction: column;
  margin: 1rem;
  position: relative;
}
.ePulse-modal .ePulse-modal-content .form-block .form-label {
  position: absolute;
  top: -10px;
  left: 10px;
  background: #fff;
  font-size: 13px;
  font-weight: 500;
  padding: 0 5px;
  z-index: 2;
}
ePulse-modal .ePulse-modal-content .form-block .form-label2 {
  position: absolute;
  top: -10px;
  left: 10px;
  background: #fff;
  font-size: 13px;
  font-weight: 500;
  padding: 0 5px;
  z-index: 0;
}

.ePulse-modal .ePulse-modal-content .form-label {
  background: #fff;
  font-size: 13px;
  font-weight: 500;
  padding: 0 10px;
}

.ePulse-modal .ePulse-modal-content select.form-control,
.ePulse-modal .ePulse-modal-content input.form-control {
  max-height: 40px;
  padding: 0.75rem 1rem;
  font-size: 1rem;
}
.ePulse-modal .ePulse-modal-content input.form-control-body {
  max-height: 40px;
  /* min-height: 60px; */
  padding: 0.75rem 1rem;
  font-size: 1rem;
}

.ePulse-modal .ePulse-modal-content input.rmdp-input {
  height: 40px;
  padding: 0.75rem 1rem;
  font-size: 1rem;
}
.ePulse-modal .ePulse-modal-content .rdw-editor-toolbar {
  padding-top: 12px;
  border: 1px solid #e4e6ef;
  border-bottom: none;
  padding-bottom: 0;
  margin-bottom: 0;
}
.ePulse-modal .ePulse-modal-content .rdw-editor-main {
  border-radius: 0 0 0.475rem 0.475rem;
}
.ePulse-modal .ePulse-modal-content .btn-row {
  text-align: end;
}
.ePulse-modal .ePulse-modal-content .btn-row .ePulse-btn-primary {
  background: linear-gradient(269.89deg, #29aaa9 0.09%, #564ea2 99.92%) !important;
  min-height: 32px;
  min-width: 100px;
  padding: 0 20px;
  border: 0;
  border-radius: 20px;
  color: #fff;
}
.ePulse-modal .ePulse-modal-content .text-danger {
  color: #ff464b;
  font-size: 12px;
  float: right;
  position: relative;
  top: -4px;
}

.btn-row {
  text-align: end;
}

.list-label {
  font-size: 13px;
  font-weight: 700;
  z-index: 2;
}

.label-block {
  display: flex;
  flex-direction: column;
  margin: 1rem 0 0 1rem;
}

.label-block .form-label {
  font-size: 13px;
  font-weight: 500;
  z-index: 2;
}

.label-form-block {
  display: flex;
  flex-direction: row;
  /* margin: 1rem; */
  /* position: relative; */
}

.ePulse-btn-primary {
  background: linear-gradient(269.89deg, #29aaa9 0.09%, #564ea2 99.92%) !important;
  min-height: 32px;
  min-width: 100px;
  padding: 0 20px;
  border: 0;
  border-radius: 20px;
  color: #fff;
}

.ePulse-btn-selected {
  background: linear-gradient(269.89deg, #29aaa9 0.09%, #564ea2 99.92%) !important;
  min-height: 32px;
  min-width: 100px;
  padding: 0 20px;
  border: 1px solid #c3c0c0;
  border-radius: 20px;
  color: #000000;
}

.ePulse-btn-secondory {
  min-height: 32px;
  min-width: 100px;
  padding: 0 20px;
  border: 1px solid #c3c0c0;
  border-radius: 20px;
  color: #000000;
}

span.tag-wrapper.ReactTags__tag {
  margin-right: 10px;
  margin-bottom: 10px;
  display: inline-block;
}
span.tag-wrapper.ReactTags__tag button {
  margin-left: 8px;
}

.section-edit-rx {
  display: flex;
  flex-direction: column;
}
.edit-rx-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 1rem;
}
.edit-rx-header h2 {
  color: #343a40;
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.edit-rx-header .rx-code {
  color: #121217;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}
.edit-rx-container {
  display: flex;
  flex-direction: row;
  background: #fff;
  border-radius: 8px;
  position: relative;
  max-height: calc(100vh - 200px);
  overflow: hidden;
}
.edit-rx-container .edit-rx-category-list {
  display: flex;
  flex-direction: column;
  margin: 1rem 0.5rem;
  padding: 0 0.5rem;
  min-width: 300px;
  height: 500px;
  overflow: hidden auto;
}
.edit-rx-container .edit-rx-category-list .add-rx-category-btn {
  border: 2px solid #398aa6;
  color: #398aa6;
  background: #fff;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  min-height: 45px;
  margin-bottom: 1rem;
}
.edit-rx-container .edit-rx-category-list .rx-category-btn {
  font-family: 'Montserrat', sans-serif;
  background: #f1f1f1;
  color: #000000;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  min-height: 45px;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
}
.edit-rx-container .edit-rx-category-list .rx-category-btn.active {
  font-family: 'Montserrat', sans-serif;
  background: #bdbdbd;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  min-height: 45px;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
}

.edit-rx-container .edit-rx-category-list .rx-category-btn:first-child {
  /* background: #bdbdbd;
  color: #ffffff; */
}
.edit-rx-container .edit-rx-category-list .rx-category-btn .drag-icon svg {
  min-height: 25px;
  margin: 0 0.5rem;
}
.edit-rx-container .edit-rx-category-list .rx-category-btn:first-child .drag-icon svg {
  filter: invert(100%) sepia(0%) saturate(7491%) hue-rotate(128deg) brightness(106%) contrast(103%);
}
.edit-rx-container .edit-rx-category-list .rx-category-btn .rx-rx-category-title {
  flex: 1;
  margin-left: 0.5rem;
}
.edit-rx-container .edit-rx-category-list .rx-category-btn .rx-category-actions {
  display: flex;
  margin-left: auto;
  margin-right: 0.5rem;
}
.edit-rx-container .edit-rx-category-list .rx-category-btn .rx-category-actions button {
  background: transparent;
  border: none;
}
.edit-rx-content {
  padding: 1rem;
  max-height: 100%;
  overflow: hidden auto;
}
.edit-rx-content .rx-category {
  margin-bottom: 2.5rem;
  /* border-bottom: 1px solid #E7E7E7; */
}
.edit-rx-content .rx-category .rx-category-header {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e7e7e7;
  padding-bottom: 0.75rem;
  margin-bottom: 0.5rem;
}

.edit-rx-content .rx-category .rx-category-header .rx-category-title {
  color: #4e4e55;
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 750;
  line-height: normal;
  flex: 1;
  /* margin-bottom: 0.5rem; */
}
.edit-rx-content .rx-category button {
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  min-height: 35px;
  border-radius: 20px;
  padding: 0 0.75rem;
}
.edit-rx-content .rx-category .add-category-btn {
  border: 1px solid #398aa6;
  color: #ffffff;
  background: #398aa6;
}
.edit-rx-content .rx-category .add-component-btn {
  border: 1px solid #398aa6;
  color: #398aa6;
  background: #fff;
}
.edit-rx-content .rx-category button:not(:last-child) {
  margin-right: 1rem;
}
.edit-rx-content .rx-category button .add-icon {
  font-size: 14px;
  margin-right: 0.5rem;
  font-weight: 600;
}
.edit-rx-content .rx-category .rx-category-body {
  border-bottom: 1px solid #e7e7e7;
}
.edit-rx-content .rx-category .rx-category-body .sub-category-content {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;
}
.edit-rx-content .rx-category .rx-category-body .sub-category-content:not(:last-child) {
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #e7e7e7;
}
.edit-rx-content .rx-category .rx-category-body .sub-category-content .sub-category-header {
  display: flex;
  align-items: center;
}
.edit-rx-content
  .rx-category
  .rx-category-body
  .sub-category-content
  .sub-category-header
  .sub-category-title {
  color: #4e4e55;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.edit-rx-content .rx-category .rx-category-body .sub-category-content .sub-category-header button {
  min-height: 30px;
}
.edit-rx-content
  .rx-category
  .rx-category-body
  .sub-category-content
  .sub-category-header
  .sub-category-actions {
  display: flex;
  flex: 1;
}
.edit-rx-content
  .rx-category
  .rx-category-body
  .sub-category-content
  .sub-category-header
  .menu-link {
  background: transparent;
  border: none;
  padding: 0;
  margin-left: 0.75rem;
  margin-right: 0;
}
.edit-rx-content
  .rx-category
  .rx-category-body
  .sub-category-content
  .sub-category-header
  .add-component-btn {
  margin-left: auto;
}

/* Component Modal  */
.ePulse-modal-content .wrapper .vertical-tabs .tab-content {
  padding: 0 1rem;
}
.ePulse-modal-content .wrapper .vertical-tabs .tab {
  margin: 0;
}
.ePulse-modal.component-modal .ePulse-modal-content {
  padding: 0 1rem;
}
.ePulse-modal .edit-rx-container .edit-rx-category-list {
  /* overflow: visible; */
}
.ePulse-modal .edit-rx-container .edit-rx-category-list .rx-category-btn {
  position: relative;
}
/* .ePulse-modal .edit-rx-container .edit-rx-category-list .rx-category-btn.active:before {
  content: " ";
  position: absolute;
  top: 50%;
  right: -12px;
  width: 0;
  height: 0;
  border-style: solid;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 12px solid #bdbdbd;
  border-right: 0;
  transform: translateY(-50%);
} */

.ePulse-modal .component-container {
  padding: 1rem;
  width: 100%;
  /* border: 1px solid #bdbdbd; */
  margin: 1rem 0.5rem;
  border-radius: 8px;
  box-shadow: 0px 2px 8px rgba(37, 51, 66, 0.15);
}
.ePulse-modal .component-container > .row {
  height: 100%;
}
.ePulse-modal .component-container > .row > .col {
  padding: 0.5rem 0 0.5rem 1rem;
}
.ePulse-modal .component-container > .row > .col:first-child {
  border-right: 1px solid #e3e3e3;
}
.ePulse-modal .component-container .form-block {
  margin-left: 0;
}

/* Component Styles */
.component-input.form-block {
  display: flex;
  flex-direction: column;
  margin: 1rem;
  position: relative;
}
.component-input.form-block .form-label {
  position: absolute;
  top: -10px;
  left: 10px;
  background: #fff;
  font-size: 13px;
  font-weight: 500;
  padding: 0 5px;
  z-index: 2;
}
.component-input {
  position: relative;
  border-radius: 5px;
  border: 1px solid #398aa6;
  flex-direction: row !important;
}
.component-input input {
  flex: 1;
  border: none;
}
.component-input .unit-label {
  min-height: calc(100% - 2px);
  min-width: 75px;
  background: #ebf5f6;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 5px 5px 0;
  border-left: 1px solid #398aa6;
}
.component-input.component-input-multi-separator .input-separator {
  font-size: 25px;
  opacity: 0.3;
}
.component-input.component-input-voice {
  border: none;
  background: #ebf4ff;
  /* min-height: 100px; */
}
.component-input.component-input-voice .form-label {
  /* position: relative; */
  display: none;
}
.component-input.component-input-voice .form-control {
  background: transparent !important;
  min-height: 100px;
  border: none;
}
.component-input.component-input-voice i {
  position: absolute;
  right: 10px;
  bottom: 5px;
  background: #398aa6;
  border-radius: 50%;
  color: #fff;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.component-input.component-input-radio {
  border: none;
}
.component-input.component-input-radio .radio-group {
  margin-right: 1rem;
  display: flex;
  align-items: center;
  gap: 5px;
}
.component-input.component-input-checkbox {
  border: none;
}
.component-input.component-input-checkbox .checkbox-group {
  margin-right: 1rem;
  display: flex;
  align-items: center;
  gap: 5px;
}
.input-container {
  margin-bottom: 0.5rem;
  min-width: 33%;
  max-width: 33%;
  float: left;
}
.input-container.input-voice {
  min-width: 100%;
  max-width: unset;
}

/* Layout Component Styles */
.layout-component-parent {
  overflow: auto;
}
.layout-component-container {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 0;
  border: 1px solid #eee;
}
.layout-component-container .component-content {
  padding: 1rem;
}
.layout-component-container h2 {
  font-size: 15px;
  background: #ebf4ff;
  padding: 1rem 0.75rem;
}
.layout-component-container .search-container {
  position: relative;
}
.layout-component-container .search-container .icon {
  width: 14px;
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  filter: invert(70%) sepia(8%) saturate(196%) hue-rotate(169deg) brightness(90%) contrast(90%);
}
.layout-component-container .search-container .icon-separator {
  position: absolute;
  top: 10px;
  left: 30px;
  width: 1px;
  height: 20px;
  background-color: #9ca1a6;
  opacity: 0.6;
}
.layout-component-container .search-container input.form-control {
  color: #4f4f4f;
  background-color: #ebf4ff;
  border: none !important;
  box-shadow: none !important;
  padding-left: 40px;
}
.layout-component-container .layout-separator {
  background-color: #9ca1a6;
  opacity: 0.6;
  height: 1px;
  display: inline-block;
  width: 100%;
  margin: 1.5rem 0;
}
.layout-component-container h3 {
  font-size: 14px;
  font-weight: 500;
}
.layout-component-container .follow-up-selector {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.layout-component-container .follow-up-selector .form-control {
  color: #4f4f4f;
  background-color: #ebf4ff;
  border: none !important;
  box-shadow: none !important;
  max-width: 100px;
}
.layout-component-container .follow-up-selector .option-selector {
  list-style-type: none;
  display: flex;
  padding: 0;
  background: #ebf4ff;
  border-radius: 25px;
}
.layout-component-container .follow-up-selector .option-selector li {
  padding: 0.65rem 1.25rem;
  font-size: 10px;
  color: #2d313d;
  position: relative;
}
.layout-component-container .follow-up-selector .option-selector li:first-child {
  border-radius: 25px 0 0 25px;
}
.layout-component-container .follow-up-selector .option-selector li:last-child {
  border-radius: 0 25px 25px 0;
}
.layout-component-container .follow-up-selector .option-selector li.active {
  background: #398aa6;
  color: #ffffff;
  border-radius: 25px;
}
.layout-component-container .visit-date .title {
  font-weight: bold;
  margin-right: 0.5rem;
}
.component-chips {
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin-top: 1rem;
}
.component-chips li {
  margin: 0 0.5rem 1rem 0;
  padding: 0.65rem 1.25rem;
  background: #ebf4ff;
  border-radius: 25px;
  font-size: 10px;
  color: #2d313d;
  position: relative;
}
.component-chips li.active {
  background: #398aa6;
  color: #ffffff;
}
.component-chips-editable li {
  padding-right: 2.5rem;
}
.component-chips-editable li .icon-separator {
  position: absolute;
  top: 9px;
  right: 26px;
  width: 1px;
  height: 15px;
  background-color: #9ca1a6;
  opacity: 0.6;
}
.component-chips-editable li .delete-icon {
  position: absolute;
  right: 0.5rem;
}
.medicine-list {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  padding: 0;
  margin: 1rem 0;
}
.medicine-list li {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 85%;
  margin-bottom: 0.5rem;
  padding-bottom: 0.5rem;
}
.medicine-list li .medical-medicine {
  font-weight: bold;
}
.medicine-list li .medical-pharmacy {
  margin-top: 0.15rem;
  font-size: 12px;
  color: #398aa6;
  font-weight: 600;
}
.medicine-list li .add-icon {
  position: absolute;
  top: 5px;
  right: 0;
  background: #ebf4ff;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  color: #398aa6;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 500;
}
.medicine-list li:not(:last-child) {
  border-bottom: 1px solid rgb(156 161 166 / 60%);
}
/* Mobile / Tablet version for APP */
@media all and (max-width: 991px) {
  div.page-title {
    display: none !important;
  }
  .header-fixed .header {
    background: linear-gradient(265deg, #29aaa9 0%, #564ea1 100%);
  }
  .sidebar {
    height: calc(100% - 75px);
    top: 75px;
  }
  .sidebar .toggle-icon {
    display: none;
  }
  .dashboard-landing {
    position: relative;
  }
  .dashboard-left {
    width: 100% !important;
  }
  .dashboard-landing .dashboard-counters {
    flex-wrap: wrap;
    margin-bottom: 800px;
    gap: 6px;
  }
  .dashboard-landing .dashboard-counters .count-block {
    min-width: calc(50% - 3px);
    gap: 10px;
    margin: 0 !important;
    padding: 10px;
    flex: 1 1;
    max-width: calc(50% - 3px);
  }
  .dashboard-right {
    position: absolute;
    top: 210px;
    margin: 0 !important;
  }
  .dashboard-header {
    flex-direction: column;
    align-items: flex-start;
  }
  .dashboard-landing .dashboard-left .dashboard-block .dashboard-block-header {
    flex-direction: column;
    align-items: flex-start;
  }
  .dashboard-landing .dashboard-left .dashboard-block .dashboard-block-header .header-left {
    width: 100%;
    margin-bottom: 10px;
  }
  .dashboard-landing .dashboard-left .dashboard-block .dashboard-block-header .link-view-all {
    margin-left: auto;
  }
  .dashboard-landing .dashboard-left .dashboard-block .dashboard-block-header .header-right {
    align-self: flex-end;
  }
  .dashboard-header .modalcontainer {
    width: 100%;
  }
  .dashboard-header .ePulse-datePickerParent {
    margin: 0 !important;
    justify-content: flex-start;
  }
  #kt_aside_toggle .svg-icon {
    color: #ffffff;
  }
  #kt_aside_toggle .svg-icon path {
    opacity: 1;
  }
  .dashboard-table-group {
    overflow: auto hidden;
  }
  .dashboard-table-row,
  .dashboard-table-group .dashboard-block-body {
    min-width: 600px;
  }
  .user-badge-block .user-role {
    color: #ffffff;
  }
  .wrapper hr {
    display: none;
  }
  .wrapper h2.page-title {
    margin: 15px 0;
  }
  .page-header {
    margin: 15px 0 5px !important;
  }
  .wrapper .vertical-tabs {
    flex-direction: column;
    position: relative;
    padding-top: 70px;
  }
  .wrapper .vertical-tabs .tab-list-container {
    position: absolute;
    top: 0;
    height: 62px;
    max-height: 65px;
    overflow: hidden;
    width: 100%;
    background: #fff;
  }

  .wrapper .vertical-tabs .tab-list-container.menuOpen {
    max-height: unset;
    height: 100%;
    min-height: 485px;
  }
  .wrapper .vertical-tabs .tab-list-container .tab.active {
    display: none;
  }
  .wrapper .vertical-tabs .tab-head {
    display: block;
  }
  .wrapper .vertical-tabs .tab-content .linear-tab-content .flex-row {
    flex-direction: column !important;
    margin: 5px 0;
  }
  .wrapper .vertical-tabs .tab-content .linear-tab-content .flex-row div[class*='col'] {
    width: 100%;
  }

  /* .wrapper .vertical-tabs .tab-content .linear-tab-content .flex-row input:enabled:hover {
    border: 1px solid #808080 !important;
  } */

  .filter-row {
    flex-wrap: wrap;
    position: relative;
    /* flex-direction: column; */
    /* align-items: flex-start; */
  }
  .filter-row .filter-head {
    margin-right: 10px;
  }
  .filter-row select,
  .filter-row input {
    min-width: 100%;
  }
  .filter-row > * {
    margin: 5px 0;
  }
  .filter-row > span {
    margin: 15px 0 5px;
  }
  .filter-row > button {
    margin-top: 10px;
  }
  .filter-row span.filter-action {
    margin: 5px 0 0;
  }
  .filter-row span.filter-action + button {
    position: absolute;
    top: -52px;
    right: 0;
  }
  .admin-table {
    overflow: auto hidden;
    /* position: relative; */
    padding-bottom: 30px;
  }
  .admin-table .pagination {
    position: absolute;
    /* bottom: 0; */
    left: 20px;
  }
  .ePulse-modal {
    margin: 1rem auto;
    min-width: 75vw;
  }
  .ePulse-modal .flex-row {
    flex-direction: column !important;
  }
}
.prescription-detail-container {
  /* display: flex;
  flex-direction: row;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  margin-right: 0.5rem;
  width: 330px;
  float: left; */
}

.prescription-category-title {
  color: #4e4e55;
  margin-bottom: 5px;
  margin-top: 5px;
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 750;
  line-height: normal;
  flex: 1;
}

.prescription-subcategory-title {
  color: #4e4e55;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.pdf-style {
  width: 100%;
  height: 200px;
}

.excel-modal-date-format-warning {
  margin: 1em 0 1em 0;
  padding: 1em 0 1em 1em;
  background-color: #ecb159;
  border-radius: 10px;
  color: #561c24;
}
.excel-header {
  min-width: 15em !important;
  /* max-width: 100px; */
}

.edit-skip-buttons {
  display: flex;
  flex-direction: row;
  justify-content: start;
  color: #006edc;
}

.excel-columns-name ul {
  flex-wrap: wrap;
  list-style: disc;
  padding: 0;
  margin: 0;
  font-size: 12px;
  display: flex;
  flex-direction: row;
}

.excel-columns-name li {
  display: inline-block;
  margin: 0 10px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}
.view-settlement-container {
  display: flex;
  flex-direction: row;
}

.view-settlement-details-container {
  display: flex;
  flex-direction: column;
  margin: 5px;
  width: 65%;
}

.view-settlement-timeline {
  display: flex;
  flex-direction: column;
  width: 35%;
  margin: 5px;
  border: 1px solid #808080;
  padding: 5px;
}

.view-settlement-amount {
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
  border: 1px solid #808080;
  padding: 5px;
}

.amount-check-icon-box {
  display: flex;
  background-color: #9dde8b;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  border-radius: 5px;
}
.amount-icon-size {
  font-size: 25px;
}

.check-icon-color {
  color: #0a6847;
}

.amount-details-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.amount-details {
  display: flex;
  flex-direction: row;
  margin: 0 0 0 10px;
  align-items: center;
  justify-content: center;
}

.inr-icon-size {
  font-size: 30px;
}

.processed-box {
  display: flex;
  flex-direction: column;
  padding: 3px;
  border-radius: 5px;
  background-color: #9dde8b;
  margin: 0 0 0 10px;
  color: #0a6847;
  font-size: 10px;
}

.created-on {
  font-size: 12px;
  margin: 0 0 0 10px;
}
.view-settlement-gross {
  margin-bottom: 5px;
  border: 1px solid #808080;
  padding: 5px;
}

.gross-heading-one {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.gross-heading-one .gross {
  font-size: 12px;
  font-weight: bold;
}

.gross-heading-one .gross-amount {
  font-size: 12px;
  font-weight: bold;
  color: #0a6847;
}

.gross-heading-two {
  font-size: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 5px 0 5px 0;
}
.gross-heading-three {
  font-size: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 5px 0 5px 0;
}

.gross-heading-three .deductions {
  font-weight: bold;
}

.gross-heading-three .deductions-amount {
  font-weight: bold;
  color: red;
}
.detailsID {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}

.detailsID .id {
  font-weight: normal;
  margin: 0 60px 0 0;
}

.view-settlement-details {
  font-size: 12px;
  border: 1px solid #808080;
  padding: 5px;
  font-weight: bold;
}

.timeline-text {
  font-weight: bold;
  font-family: 'Montserrat', sans-serif;
  margin-bottom: 10px;
}

.settlement-created-container {
  display: flex;
  flex-direction: column;
}

.icon-settlement-created {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  margin-bottom: 10px;
}

.icon-bg {
  display: flex;
  background-color: #9dde8b;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  border-radius: 9px;
}

.headings {
  display: flex;
  flex-direction: column;
}

.heading-one {
  font-size: 12px;
  margin: 0px 10px 0px 10px;
  font-weight: bold;
}
.heading-two {
  font-size: 12px;
  margin: 0px 0px 0px 12px;
  color: #000000;
}

.heading-three {
  font-size: 12px;
  columns: #808080;
  margin: 0px 0px 0px 12px;
  color: rgb(145, 145, 145);
}

.gross-settlement-container {
  font-size: 12px;
  display: flex;
  flex-direction: column;
  margin: 5px;
  border: 1px solid #808080;
  padding: 5px;
  font-weight: bold;
}

hr.hr-rule {
  margin: 1rem 0;
  border-top: 1px solid;
  opacity: 0.25;
  /* width: 100%; */
}

.summary-container {
  display: flex;
  flex-direction: column;
  padding: 5px;
}

.summary-container .transactionsRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.summary-container .transactionsRow .transaction-box {
  background-color: #fff;
  padding: 10px 0 10px 0;
  width: 49%;
}

.summary-container .transactionsRow .transaction-box-total {
  background-color: #fff;
  padding: 10px 0 10px 0;
  width: 25%;
}

.heading {
  font-size: 17px;
  font-weight: 600;
  color: #838383;
  margin-left: 10px;
}

.seperator {
  border-width: 1px;
  border-color: #bcbcbc;
  margin-top: 10px;
  margin-bottom: 10px;
}

.vertical {
  border-left: 1px solid #bcbcbc;
}

.amount-box {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  width: 50%;
}

.amount-box .sub-heading {
  font-size: 15px;
  font-weight: 400;
  color: #838383;
  margin-left: 10px;
}

.amount-box .amount {
  font-size: 25px;
  font-weight: 600;
  margin-left: 10px;
}

.graph-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;
  gap: 10px;
}

.graph-box {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 10px 0 10px 0;
  /* width: 33%; */
}

.graph-sub-heading {
  font-size: 15px;
  font-weight: 600;
  margin-left: 10px;
}

.image-container {
  position: relative;
  display: inline-block;
  /* width: 80px;
  height: 80px; */
  /* background-color: #dc3545; */
  border-radius: 8px;
}

.delete-icon {
  /* position: absolute; */
  height: 20px;
  width: 20px;
  top: -15px;
  right: -5px;
  z-index: 10;
  padding: 5px;
  border-radius: 50%;
}

.template-header {
  background: linear-gradient(270deg, #29aaa9 0.09%, #564ea1 99.92%);
  text-align: center;
  margin: 10px 0;
  color: #fff;
  margin: 0;
}

.header-content {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  padding: 10px 5px;
}

.template-container {
  width: 750px;
  margin: 0 auto;
  font-size: 14px;
}

.template-logo {
  max-height: 24px;
}

.doctor-details {
  padding: 10px 20px;
  display: flex;
  width: 100%;
}

.seperator-line {
  background-color: #407ca5;
  height: 1px;
}

.template-body {
  padding: 0 0;
  margin-top: 0.5rem;
}

.patient-details {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.label {
  font-weight: bold;
}

.patient-details-box {
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-width: 50%;
}
.category {
  font-size: 20px;
  margin-bottom: 4px;
  border-bottom: 1px solid #dddddd;
}

.magin-20 {
  margin-top: 20px;
}

.margin-10 {
  margin-bottom: 10px;
}

.table-head {
  padding: 4px;
  vertical-align: top;
  border-bottom: 1px solid #eee;
}

.table-data {
  padding: 4px;
  vertical-align: top;
}

.label-2 {
  font-size: 12px;
}

.label-3 {
  font-size: 18px;
}

.label-4 {
  font-size: 16px;
}

.template-footer {
  padding: 8px 20px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.footer-img {
  max-height: 16px;
  margin-right: 3px;
  position: relative;
  /* top: 5px; */
}

.footer-line-1 {
  background: linear-gradient(270deg, #29aaa9 0.09%, #564ea1 99.92%);
  height: 5px;
}

.footer-line-2 {
  background: #cccccc;
  height: 15px;
}

/* AI REPORT CSS START */

.ai-container {
  width: 100%;
  height: 80vh;
  display: grid;
}

.ai-header {
  position: sticky;
  z-index: 10;
  display: flex;
  height: 57px;
  align-items: center;
  border-bottom-width: 1px;
  /* background-color: #ffffff; */
  padding: 0 10px 0 10px;
}

.ai-header-h1 {
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 600;
  width: 200px;
}

.ai-header-content {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-end;
}

.ai-header-token-used {
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
}

.ai-header-custom-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  font-weight: 500;
  outline: none;
  transition: background-color 0.2s, color 0.2s;
  height: 2.5rem;
  width: 2.5rem;
  border: 1px solid transparent;
  background-color: #ffffff;
  color: #333333;
}

.ai-header-custom-button:focus-visible {
  outline: 2px solid transparent;
  box-shadow: 0 0 0 2px #3b82f6;
  background-color: #ffffff;
}

.ai-header-custom-button:disabled {
  pointer-events: none;
  opacity: 0.5;
}

.ai-header-custom-button:hover {
  background-color: #ff8f00;
  color: #ffffff;
}

@media (min-width: 768px) {
  .ai-header-custom-button {
    display: none;
  }
}

.ai-sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.ai-max-h-80vh {
  max-height: 80vh;
}
.ai-grid-container {
  display: grid;
  flex: 1;
  gap: 1rem;
  padding: 1rem;
  max-height: 75vh;
  grid-template-columns: 1fr;
}

@media (min-width: 768px) {
  .ai-grid-container {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (min-width: 1024px) {
  .ai-grid-container {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 1023px) {
  .ai-grid-container {
    gap: 1rem 0;
  }
}

@media (max-width: 991px) {
  .ai-header {
    margin-top: 2rem;
  }
}

.ai-hidden-md-flex {
  display: none;
  flex-direction: column;
}

@media (min-width: 768px) {
  .ai-hidden-md-flex {
    display: flex;
  }
}

.ai-lg-col-span-2 {
  grid-column: span 2 / span 2;
}

@media (min-width: 1024px) {
  .ai-lg-col-span-2 {
    grid-column: span 2 / span 2;
  }
}

.ai-report-content-container {
  display: grid;
  width: 100%;
  align-items: start;
  gap: 1.5rem;
  overflow: auto;
  padding: 1rem;
  padding-top: 0;
  background-color: white;
  border-radius: 0.75rem;
}

.ai-report-content-grid {
  position: relative;
  display: grid;
  gap: 1.5rem;
  border-radius: 0.5rem;
  border: 1px solid #e5e7eb;
  padding: 1rem;
}

.ai-text-sm-font-medium {
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.ai-extracting-overlay {
  position: absolute;
  z-index: 10;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.ai-custom-input {
  display: flex;
  height: 2.5rem;
  width: 100%;
  border-radius: 0.375rem;

  background-color: #f9fafb;
  padding: 0.5rem 0.75rem;
  font-size: 0.875rem;
  outline: none;
}

.ai-custom-input::placeholder {
  color: #6b7280;
}

.ai-custom-input:focus-visible {
  outline: 2px solid transparent;
  box-shadow: 0 0 0 2px #3b82f6;
  background-color: #ffffff;
}

.ai-custom-input:focus-visible:focus {
  box-shadow: 0 0 0 2px #3b82f6;
  border-color: #3b82f6;
}

.ai-custom-input:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.ai-custom-input input[type='file'] {
  border: 0;
  background-color: transparent;
  font-size: 0.875rem;
  font-weight: 500;
}

.ai-custom-input input[type='file']::file-selector-button {
  font-size: 0.875rem;
  font-weight: 500;
}

.ai-fileTitle {
  font-size: 1.125rem;
  font-weight: 700;
}

.ai-file-button {
  cursor: pointer;
  max-width: 100%;
  border-radius: 0.375rem;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

.ai-file-button-file {
  color: #3b82f6;
  text-decoration: underline;
}

.ai-file-button-img {
  width: 100%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
}

.ai-modal-container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 50;
}

.ai-modal-box {
  position: relative;
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
}

.ai-modal-box-pdf {
  width: 100vh;
  height: 100vh;
}

.ai-modal-box-img {
  max-width: 100%;
  max-height: 90vh;
  border-radius: 0.5rem;
}

.ai-chat-container {
  height: 100%;
  background-color: white;
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 75vh;
  border-radius: 0.75rem;
  padding: 1rem;
  gap: 1rem;
}
.ai-chat-container-box {
  display: flex;
  flex-direction: row;
  justify-content: start;
  padding-bottom: 0.5rem;
  position: absolute;
  right: 1.5rem;
  top: 0.375rem;
}
.ai-chat-container-select {
  display: flex;
  flex-direction: row;
  position: sticky;
  right: 1.5rem;
  top: 0.375rem;
}

.ai-button-color1 {
  background-color: #42f5ef;
}

.ai-button-color2 {
  background-color: #00b612;
}

.bg-color-button {
  margin: 0 4px 0 4px;
  display: inline-flex;
  align-items: center;
  border-radius: 9999px;
  border: 1px solid #000;
  padding: 0.125rem 0.625rem;
  font-size: 0.75rem;
  font-weight: 600;
  transition: color 0.3s ease, background-color 0.3s ease;
  border: 1px solid transparent;
  transition: background-color 0.3s ease;
}

.bg-color-button-default {
  border: 1px solid transparent;
  color: #ffffff;
  background-color: rgba(52, 152, 219, 0.8);
  transition: background-color 0.3s ease;
  margin: 0 4px 0 4px;
  display: inline-flex;
  align-items: center;
  border-radius: 9999px;
  padding: 0.125rem 0.625rem;
  font-size: 0.75rem;
  font-weight: 600;
  border: 1px solid transparent;
}

.bg-color-button-secondary {
  margin: 0 4px 0 4px;
  display: inline-flex;
  align-items: center;
  border-radius: 9999px;
  padding: 0.125rem 0.625rem;
  font-size: 0.75rem;
  font-weight: 600;
  border: 1px solid transparent;
  background-color: rgba(241, 196, 15, 0.8);
  border: 1px solid transparent;
  color: #333333;
  transition: background-color 0.3s ease;
}

.bg-color-button-destructive {
  border: 1px solid transparent;
  background-color: rgba(231, 76, 60, 0.8);
  margin: 0 4px 0 4px;
  display: inline-flex;
  align-items: center;
  border-radius: 9999px;
  padding: 0.125rem 0.625rem;
  font-size: 0.75rem;
  font-weight: 600;
  border: 1px solid transparent;
  color: #ffffff;
  transition: background-color 0.3s ease;
}

.bg-color-button-outline {
  margin: 0 4px 0 4px;
  display: inline-flex;
  align-items: center;
  color: #333333;
  border-radius: 9999px;
  border: 1px solid #000;
  padding: 0.125rem 0.625rem;
  font-size: 0.75rem;
  font-weight: 600;
  transition: color 0.3s ease, background-color 0.3s ease;
  border: 1px solid transparent;
  transition: background-color 0.3s ease;
}

.ai-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  font-weight: 500;
  transition: color 0.2s ease, background-color 0.2s ease;
  outline: none;
}

.ai-button:focus-visible {
  outline: none;
  box-shadow: 0 0 0 2px var(--ring-color), 0 0 0 4px var(--background-color);
}

.ai-button:disabled {
  pointer-events: none;
  opacity: 0.5;
}

.ai-button-hover {
  background-color: #3490dc;
  color: #ffffff;
  transition: background-color 0.2s ease;
}

.ai-button-hover:hover {
  background-color: rgba(52, 144, 220, 0.9);
}

.ai-button-destructive {
  background-color: #e3342f;
  color: #ffffff;
  transition: background-color 0.2s ease;
}

.ai-button-destructive:hover {
  background-color: rgba(227, 52, 47, 0.9);
}

.ai-button-outline {
  border: 1px solid #d1d5db;
  background-color: #ffffff;
  transition: background-color 0.2s ease, color 0.2s ease;
}

.ai-button-outline:hover {
  background-color: #f9a8d4;
  color: #1f2937;
}

.ai-button-secondary {
  background-color: #22c55e;
  color: #ffffff;
  transition: background-color 0.2s ease;
}

.ai-button-secondary:hover {
  background-color: rgba(34, 197, 94, 0.8);
}

.ai-button-ghost {
  transition: background-color 0.2s ease, color 0.2s ease;
}

.ai-button-ghost:hover {
  background-color: #f9a8d4;
  color: #1f2937;
}

.ai-button-link {
  color: #3490dc;
  text-underline-offset: 4px;
  transition: text-decoration 0.2s ease;
}

.ai-button-link:hover {
  text-decoration: underline;
}

.ai-button-size-default {
  height: 2.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.ai-button-size-sm {
  height: 2.25rem;
  border-radius: 0.375rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.ai-button-size-lg {
  height: 2.75rem;
  border-radius: 0.375rem;
  padding-left: 2rem;
  padding-right: 2rem;
}

.ai-button-size-icon {
  height: 1rem;
  width: 2.5rem;
}

.ai-chat-flex {
  flex: 1;
  overflow-y: auto;
  max-height: 60vh;
}

.ai-accordion-text {
  font-size: 0.875rem;
}

.ai-accordion-flex {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
}

.ai-accordion-wrap {
  white-space: normal;
}

.ai-form-container {
  position: relative;
  overflow: hidden;
  border-radius: 0.5rem;
  border: 1px solid #d1d5db;
  background-color: #ffffff;
}

.ai-form-textArea {
  width: 100%;
  min-height: 50px;
  resize: none;
  border: 0;
  padding: 0.75rem;
  box-shadow: none;
  outline: none;
}

.ai-ask-button-box {
  position: absolute;
  z-index: 10;
  top: 15px;
  right: 4px;
  align-items: center;
  display: flex;
}

.ai-ask-button {
  background: linear-gradient(269.89deg, #29aaa9 0.09%, #564ea2 99.92%) !important;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
  border-radius: calc(0.5rem - 2px);
  border: 0;
  white-space: nowrap;
  justify-content: center;
  align-items: center;
  height: 2.75rem;
  display: inline-flex;
  margin-left: auto;
  color: #ffffff;
  /* width: 5rem; */
}

.ai-ask-button button:focus {
  outline: 4px solid #000000;
}

.ai-spinner {
  width: 1.5rem;
  height: 1.5rem;
  border: 2px solid #ffffff;
  border-top: 2px solid #ffffff;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.ai-cornerDownLeft {
  width: 1.3rem;
  height: 1.3rem;
}

.ai-message {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.ai-border-bottom {
  border-bottom: 1px solid #000;
}

.ai-accordion-trigger {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
  width: 100%;
  transition: text-decoration 0.3s ease;
}

.ai-accordion-trigger:hover {
  text-decoration: underline;
}
.ai-accordion-icon {
  height: 1rem;
  width: 1rem;
  flex-shrink: 0;
  transition: transform 200ms ease;
}

.ai-accordion-content {
  overflow: hidden;
  font-size: 0.875rem;
  transition: all 0.2s ease;
}

.ai-accordion-content[data-state='closed'] {
  animation: accordion-up 0.3s ease-out;
}

.ai-accordion-content[data-state='open'] {
  animation: accordion-down 0.3s ease-out;
}

@keyframes accordion-up {
  0% {
    max-height: 0;
    opacity: 0;
  }
  100% {
    max-height: 500px;
    opacity: 1;
  }
}

@keyframes accordion-down {
  0% {
    max-height: 500px;
    opacity: 1;
  }
  100% {
    max-height: 0;
    opacity: 0;
  }
}

.ai-accordion-custom-padding {
  padding-top: 0;
  padding-bottom: 1rem;
}

.ai-card {
  padding-top: 10px;
  border-radius: 0.5rem;
  border: 1px solid #d1d5db;
  background-color: #f9fafb;
  color: #111827;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.ai-card-footer-main {
  display: flex;
  align-items: center;
  padding: 1.5rem;
  padding-top: 0;
}

.ai-card-footer {
  border-top: 1px solid #d1d5db;
  background-color: rgba(158, 158, 158, 0.7);
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  font-size: 0.75rem;
  color: #6b7280;
}

.ai-card-box {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 0.5rem;
}

.ai-card-token-used {
  font-size: 0.875rem;
  font-weight: 500;
  color: #6b7280;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

.ai-card-header {
  display: flex;
  flex-direction: column;
  gap: 0.375rem;
  padding: 1.5rem;
}

.ai-card-title {
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1;
  letter-spacing: -0.01562em;
}

.ai-card-description {
  font-size: 0.875rem;
  color: #6b7280;
}

.ai-drawer-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 50;
  background-color: rgba(0, 0, 0, 0.8);
}

.ai-drawer-content {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 50;
  margin-top: 6rem;
  display: flex;
  height: auto;
  flex-direction: column;
  border-radius: 10px 10px 0 0;
  border: 1px solid #d1d5db;
  background-color: #f9fafb;
}

.ai-drawer-primitive {
  margin-left: auto;
  margin-right: auto;
  margin-top: 1rem;
  height: 0.5rem;
  width: 100px;
  border-radius: 9999px;
  background-color: #6b7280;
}

.ai-drawer-header {
  display: grid;
  gap: 0.375rem;
  padding: 1rem;
  text-align: center;
}

@media (min-width: 640px) {
  .ai-drawer-header {
    text-align: left;
  }
}

.ai-drawer-footer {
  margin-top: auto;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1rem;
}

.ai-drawer-title {
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1;
  letter-spacing: -0.01562em;
}

.ai-drawer-description {
  font-size: 0.875rem;
  color: #6b7280;
}

.ai-tooltip {
  z-index: 50;
  overflow: hidden;
  border-radius: 0.375rem;
  border: 1px solid #e5e7eb;
  background-color: #f3f4f6;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 0.875rem;
  color: #6b7280;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  animation: fade-in-0 0.3s ease-in, zoom-in-95 0.3s ease-in;
}

.ai-tooltip[data-state='closed'] {
  animation: fade-out-0 0.3s ease-out, zoom-out-95 0.3s ease-out;
}

.ai-tooltip[data-side='bottom'] {
  animation: slide-in-from-top-2 0.3s ease-out;
}

.ai-tooltip[data-side='left'] {
  animation: slide-in-from-right-2 0.3s ease-out;
}

.ai-tooltip[data-side='right'] {
  animation: slide-in-from-left-2 0.3s ease-out;
}

.ai-tooltip[data-side='top'] {
  animation: slide-in-from-bottom-2 0.3s ease-out;
}

@keyframes fade-in-0 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes zoom-in-95 {
  0% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes fade-out-0 {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes zoom-out-95 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.95);
  }
}

@keyframes slide-in-from-top-2 {
  0% {
    transform: translateY(-20px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-in-from-right-2 {
  0% {
    transform: translateX(20px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slide-in-from-left-2 {
  0% {
    transform: translateX(-20px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slide-in-from-bottom-2 {
  0% {
    transform: translateY(20px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.ai-toaster {
  display: grid;
  gap: 0.25rem;
}

.ai-textarea {
  display: flex;
  min-height: 80px;
  width: 100%;
  border-radius: 0.375rem;
  border: 1px solid #e5e7eb;
  background-color: #f3f4f6;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 0.875rem;
  color: #6b7280;
  outline: none;
  box-shadow: 0 0 0 2px #d4d4d8, 0 0 0 4px #f3f4f6;
}

.ai-textarea::placeholder {
  color: #6b7280;
}

.ai-textarea:focus-visible {
  outline: none;
  box-shadow: 0 0 0 2px #d4d4d8, 0 0 0 4px #f3f4f6;
}

.ai-textarea:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
.ai-separator-2 {
  height: 100%;
  width: 1px;
}

.ai-separator-1 {
  height: 1px;
  width: 100%;
}

.ai-dropdown-primitive {
  display: flex;
  cursor: default;
  user-select: none;
  align-items: center;
  border-radius: 0.125rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 0.875rem;
  outline: none;
}

.ai-dropdown-primitive:focus {
  background-color: #34d399;
}

.ai-dropdown-primitive[data-state='open'] {
  background-color: #34d399;
}

.ai-dropdown-chevronright {
  margin-left: auto;
  height: 1rem;
  width: 1rem;
}

.ai-dropdown-subcontent {
  z-index: 50;
  min-width: 8rem;
  overflow: hidden;
  border-radius: 0.375rem;
  border: 1px solid #d1d5db;
  background-color: #f3f4f6;
  padding: 0.25rem;
  color: #6b7280;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.ai-dropdown-subcontent[data-state='open'] {
  animation: fadeInZoomIn 0.3s ease-in-out, slideIn 0.3s ease-in-out;
}

.ai-dropdown-subcontent[data-state='closed'] {
  animation: fadeOutZoomOut 0.3s ease-in-out, slideOut 0.3s ease-in-out;
}

@keyframes fadeInZoomIn {
  0% {
    opacity: 0;
    transform: scale(0.95);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes fadeOutZoomOut {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(0.95);
  }
}

@keyframes slideInFromTop {
  0% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(10px);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-10px);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slideInFromBottom {
  0% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0);
  }
}

.ai-dropdown-subcontent[data-side='bottom'] {
  animation: slideInFromTop 0.3s ease-in-out;
}

.ai-dropdown-subcontent[data-side='left'] {
  animation: slideInFromRight 0.3s ease-in-out;
}

.ai-dropdown-subcontent[data-side='right'] {
  animation: slideInFromLeft 0.3s ease-in-out;
}

.ai-dropdown-subcontent[data-side='top'] {
  animation: slideInFromBottom 0.3s ease-in-out;
}

.ai-dropdown-content {
  position: relative;
  z-index: 50;
  min-width: 8rem;
  overflow: hidden;
  border-radius: 0.375rem;
  border: 1px solid #d1d5db;
  background-color: #f3f4f6;
  padding: 0.25rem;
  color: #111827;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.ai-dropdown-content[data-state='open'] {
  animation: fade-in 0.3s ease-in, zoom-in 0.3s ease-in;
}

.ai-dropdown-content[data-state='closed'] {
  animation: fade-out 0.3s ease-out, zoom-out 0.3s ease-out;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes zoom-in {
  0% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes zoom-out {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.95);
  }
}

.ai-dropdown-content[data-side='bottom'] {
  animation: slide-in-from-top 0.3s ease-in;
}

.ai-dropdown-content[data-side='left'] {
  animation: slide-in-from-right 0.3s ease-in;
}

.ai-dropdown-content[data-side='right'] {
  animation: slide-in-from-left 0.3s ease-in;
}

.ai-dropdown-content[data-side='top'] {
  animation: slide-in-from-bottom 0.3s ease-in;
}

@keyframes slide-in-from-top {
  0% {
    transform: translateY(-10px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-in-from-right {
  0% {
    transform: translateX(10px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slide-in-from-left {
  0% {
    transform: translateX(-10px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slide-in-from-bottom {
  0% {
    transform: translateY(10px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.ai-dropdown-item {
  position: relative;
  display: flex;
  cursor: default;
  user-select: none;
  align-items: center;
  border-radius: 0.125rem;
  padding: 0.375rem 0.5rem;
  font-size: 0.875rem;
  outline: none;
  transition: background-color 0.3s, color 0.3s;
}

.ai-dropdown-item:focus {
  background-color: #3b82f6;
  color: #ffffff;
}

.ai-dropdown-item[data-disabled='true'] {
  pointer-events: none;
  opacity: 0.5;
}

.ai-dropdown-checkboxitem {
  position: relative;
  display: flex;
  cursor: default;
  user-select: none;
  align-items: center;
  border-radius: 0.125rem;
  padding: 0.375rem 0.5rem 0.375rem 2rem;
  font-size: 0.875rem;
  outline: none;
  transition: background-color 0.3s, color 0.3s;
}

.ai-dropdown-checkboxitem:focus {
  background-color: #3b82f6;
  color: #ffffff;
}

.ai-dropdown-checkboxitem[data-disabled='true'] {
  pointer-events: none;
  opacity: 0.5;
}

.ai-dropdown-indicator {
  position: absolute;
  left: 0.5rem;
  display: flex;
  height: 1.375rem;
  width: 1.375rem;
  align-items: center;
  justify-content: center;
}

.ai-dropdown-radioitem {
  position: relative;
  display: flex;
  cursor: default;
  user-select: none;
  align-items: center;
  border-radius: 0.125rem;
  padding: 0.375rem 0.5rem 0.375rem 2rem;
  font-size: 0.875rem;
  outline: none;
  transition: background-color 0.3s, color 0.3s;
}

.ai-dropdown-radioitem:focus {
  background-color: #3b82f6;
  color: #ffffff;
}

.ai-dropdown-radioitem[data-disabled='true'] {
  pointer-events: none;
  opacity: 0.5;
}

.ai-dropdown-radioitem-span {
  position: absolute;
  left: 0.5rem;
  display: flex;
  height: 1.375rem;
  width: 1.375rem;
  align-items: center;
  justify-content: center;
}

.ai-dropdown-label {
  padding: 0.375rem 0.5rem;
  font-size: 0.875rem;
  font-weight: 600;
}

.ai-dropdown-separator {
  margin-left: -0.25rem;
  margin-right: -0.25rem;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  height: 1px;
  background-color: #e4e4e7;
}

.ai-dropdown-dropdownmenushortcut {
  margin-left: auto;
  font-size: 0.75rem;
  letter-spacing: 0.1em;
  opacity: 0.6;
}

.ai-toast-viewport {
  position: fixed;
  top: 0;
  z-index: 100;
  display: flex;
  flex-direction: column-reverse;
  max-height: 100vh;
  width: 100%;
  padding: 1rem;
}

@media (min-width: 640px) {
  .ai-toast-viewport {
    bottom: 0;
    right: 0;
    top: auto;
    flex-direction: column;
  }
}

@media (min-width: 768px) {
  .ai-toast-viewport {
    max-width: 420px;
  }
}

.ai-toast-toastvariant {
  pointer-events: auto;
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  overflow: hidden;
  border-radius: 0.375rem;
  border: 1px solid #ccc;
  padding: 1.5rem 2rem 1.5rem 1.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.ai-toast-toastvariant[data-swipe='cancel'] {
  transform: translateX(0);
}

.ai-toast-toastvariant[data-swipe='end'] {
  transform: translateX(var(--radix-toast-swipe-end-x));
}

.ai-toast-toastvariant[data-swipe='move'] {
  transform: translateX(var(--radix-toast-swipe-move-x));
  transition: none;
}

.ai-toast-toastvariant[data-state='open'] {
  animation: fadeIn 0.3s ease-in;
}

.ai-toast-toastvariant[data-state='closed'] {
  animation: fadeOut 0.3s ease-out, slideOutRight 0.5s ease-in-out;
  opacity: 0.2;
  transform: translateX(100%);
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes slideOutRight {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}

@keyframes slideInFromTopFull {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes slideInFromBottomFull {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

@media (min-width: 640px) {
  .ai-toast-toastvariant[data-state='open'] {
    animation: slideInFromBottomFull 0.5s ease-out;
  }
}

.ai-toast-toastvariant-default {
  border: 1px solid #d1d5db;
  background-color: #f9fafb;
  color: #111827;
}

.ai-toast-toastvariant-destructive {
  border: 1px solid #f87171;
  background-color: #f87171;
  color: #ffffff;
}

.ai-toast-action {
  display: inline-flex;
  height: 2rem;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  border-radius: 0.375rem;
  border: 1px solid transparent;
  background-color: transparent;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  font-size: 0.875rem;
  font-weight: 500;
  transition: background-color 0.3s ease, border-color 0.3s ease, box-shadow 0.3s ease;
}

.ai-toast-action:hover {
  background-color: #3b82f6;
}

.ai-toast-action:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.5), 0 0 0 4px rgba(0, 0, 0, 0.1);
}

.ai-toast-action:disabled {
  pointer-events: none;
  opacity: 0.5;
}

.ai-toast-action {
  border-color: #e5e7eb;
  background-color: #f87171;
  color: #ffffff;
}

.ai-toast-action:hover {
  border-color: #f87171;
}

.ai-toast-action:focus {
  box-shadow: 0 0 0 2px #f87171, 0 0 0 4px rgba(0, 0, 0, 0.1);
}

.ai-toast-close {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  border-radius: 0.375rem;
  padding: 0.25rem;
  color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.ai-toast-close:hover {
  color: #1f2937;
}

.ai-toast-close:focus {
  opacity: 1;
  outline: none;
  box-shadow: 0 0 0 2px #3b82f6;
}

.ai-toast-close.group:hover {
  opacity: 1;
}

.ai-toast-close.group.destructive {
  color: #ef4444;
}

.ai-toast-close.group.destructive:hover {
  color: #fef2f2;
}

.ai-toast-close.group.destructive:focus {
  box-shadow: 0 0 0 2px #ef4444;
  background-color: #7f1d1d;
}

.ai-select-trigger {
  display: flex;
  height: 2.5rem;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  border-radius: 0.375rem;
  border: 1px solid #d1d5db;
  background-color: #ffffff;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 0.875rem;
  outline: none;
  transition: all 0.2s ease;
}

.ai-select-trigger::placeholder {
  color: #9ca3af;
}

.ai-select-trigger:focus {
  box-shadow: 0 0 0 2px #3b82f6;
}

.ai-select-trigger:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.ai-select-trigger > span {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
}

.ai-select-content {
  position: relative;
  z-index: 50;
  max-height: 24rem;
  min-width: 8rem;
  overflow: hidden;
  border-radius: 0.375rem;
  border: 1px solid;
  background-color: #ffffff;
  color: #333333;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.ai-select-content[data-state='open'] {
  animation: selectfadeIn 0.3s ease-in, selectzoomIn 0.3s ease-in;
}

.ai-select-content[data-state='closed'] {
  animation: selectfadeOut 0.3s ease-out, selectzoomOut 0.3s ease-out;
}

.ai-select-content[data-side='bottom'] {
  animation: selectslideInFromTop 0.3s ease-in;
}

.ai-select-content[data-side='left'] {
  animation: selectslideInFromRight 0.3s ease-in;
}

.ai-select-content[data-side='right'] {
  animation: selectslideInFromLeft 0.3s ease-in;
}

.ai-select-content[data-side='top'] {
  animation: selectslideInFromBottom 0.3s ease-in;
}

@keyframes selectfadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes selectfadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes selectzoomIn {
  from {
    transform: scale(0.95);
  }
  to {
    transform: scale(1);
  }
}

@keyframes selectzoomOut {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(0.95);
  }
}

@keyframes selectslideInFromTop {
  from {
    transform: translateY(-20px);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes selectslideInFromRight {
  from {
    transform: translateX(20px);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes selectslideInFromLeft {
  from {
    transform: translateX(-20px);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes selectslideInFromBottom {
  from {
    transform: translateY(20px);
  }
  to {
    transform: translateY(0);
  }
}

.ai-select-popper[data-side='bottom'] {
  transform: translateY(0.25rem);
}

.ai-select-popper[data-side='left'] {
  transform: translateX(-0.25rem);
}

.ai-select-popper[data-side='right'] {
  transform: translateX(0.25rem);
}

.ai-select-popper[data-side='top'] {
  transform: translateY(-0.25rem);
}

.ai-select-separator {
  margin-left: -0.25rem;
  margin-right: -0.25rem;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  height: 1px;
  background-color: #e4e4e7;
}

.ai-select-chevrondown {
  height: 1rem;
  width: 1rem;
  opacity: 0.5;
}

.ai-select-scrollupbutton {
  display: flex;
  cursor: default;
  align-items: center;
  justify-content: center;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.ai-select-hight-width {
  height: 1rem;
  width: 1rem;
}
.ai-select-scrolldownbutton {
  display: flex;
  cursor: default;
  align-items: center;
  justify-content: center;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.ai-select-viewport {
  /* height: var(--radix-select-trigger-height);  */
  width: 100%; /* Full width */
  /* min-width: var(--radix-select-trigger-width); */
}

.ai-select-label {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  padding-left: 2rem;
  padding-right: 0.5rem;
  font-size: 0.875rem;
  font-weight: 600;
}

.ai-select-item {
  position: relative;
  display: flex;
  width: 100%;
  cursor: default;
  user-select: none;
  align-items: center;
  justify-content: center;
  border-radius: 0.125rem;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  padding-left: 2rem;
  padding-right: 0.5rem;
  font-size: 0.875rem;
  outline: none;
}

.ai-select-item:focus {
  background-color: #ff5733;
  color: #ffffff;
}

.ai-select-item:disabled {
  pointer-events: none;
  opacity: 0.5;
}

.ai-select-item-span {
  position: absolute;
  left: 0.5rem;
  display: flex;
  height: 1.375rem;
  width: 1.375rem;
  align-items: center;
  justify-content: center;
}

/* AI REPORT CSS END */
